import React, { Component } from "react"
import { Link } from "gatsby"
import { ContactForm } from "../components/contact-form"
import { StaticImage } from "gatsby-plugin-image"

export class ContactBody extends Component {
    render() {
        return (
            <div className="bg-dhgray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-2">
                        <div className="px-6 leading-8">
                            <h1 className="font-semibold text-2xl">Connect With Dahlia Homes!</h1>
                            <p className="py-2">Have some questions you want to ask us?</p>
                            <p>Excellent! We love talking with people to see how we can help you reach your goals.</p>

                            <p className="py-2 font-semibold">If You Have Questions About:</p>

                            <ul className="list-disc lg:ml-5">
                                <li>Our process for making you an all-cash offer for your house</li>
                                <li>Our process for helping homeowners stop foreclosure in Sacramento</li>
                                <li>Our company and who we are</li>
                                <li>Or anything else you want to ask us</li>
                            </ul>

                            <p className="py-2">Feel Free to <a className="text-dhred hover:opacity-70" href="tel:916-245-0073" title="Call Dahlia Homes" alt="Call Dahlia Homes">Call</a>, Text, or <a href="mailto:sell@dahliahomes.com" title="Email Dahlia Homes" alt="Email Dahlia Homes" className="text-dhred hover:opacity-70">E-mail</a> us anytime! We’ll get back to you within 24 hours. If you need us quicker, make sure to call us!</p>
                            <p className="py-2">We look forward to hearing from you!</p>

                            <h2 className="font-bold text-xl py-2">Learn The Pros and Cons Of Selling Your House To A Local Professional Home Buyer– FREE Guide:</h2>

                            <div className="py-4">
                                <div className="pb-6 flex justify-center">
                                    <StaticImage src="../images/free-guide.jpeg" className="" loading="eager" placeholder="none" title="Dahlia Homes" alt="Dahlia Homes" />
                                </div>
                                <span>Need more information on The Pros and Cons Of Selling Your House? <Link to="/home-sellers-guide" className="text-dhred hover:opacity-70">Download our FREE Guide here.</Link></span>
                            </div>
                        </div>

                        <div className="mx-auto py-4">
                            <div className="flex justify-center">
                                <ContactForm formName="Contact Page" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}