import * as React from "react"
import Layout from "../components/layout"
import { ContactBody } from "../components/contact-body"
import Seo from "../components/seo"

const ContactPage = (props) => {
    return (
        <Layout>
            <Seo
                title="Connect With Dahlia Homes - Sell Your House In Sacramento"
                description="Connect with Dahlia Homes to help you sell your house in Sacramento. If you have any
                questions, need advice, or need some clarification, Dahlia Homes is here to help you with selling your 
                Sacramento house."
                location={props.location.pathname}
            />
            <div>
                <ContactBody />
            </div>
        </Layout>
    )
}
export default ContactPage