import React, { Component } from "react"

export class ContactForm extends Component {
    render() {
        return (
            <form name={this.props.formName} method="POST" data-netlify="true" className="">
                <input type="hidden" name="form-name" value={this.props.formName} />

                <div className="border bg-gradient-to-r from-dhgreenfrom to-dhgreento rounded-xl sm:w-96 mx-auto md:w-96 h-fit shadow-2xl">
                    <h2 className="font-bold px-5 py-3 text-xl text-white text-center">Contact Us!</h2>
                    <hr className="border" />
                    <div className="bg-white px-5 rounded-b-xl">

                        <div className="flex justify-center">
                            <div className="px-5 py-7 bg-white bg-opacity-30 rounded-xl">
                                <div className="flex flex-wrap -mx-2">
                                    <div className="w-full md:w-1/2 px-1 md:mb-0">
                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">First Name:
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" name="first name" placeholder="Ex: Jane" />
                                        </label>
                                    </div>
                                    <div className="w-full md:w-1/2 px-1">
                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">Last Name:
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" name="last name" placeholder="Ex: Doe" />
                                        </label>
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-2 mb-6">
                                    <div className="w-full md:mb-0 px-1">
                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">Phone:
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" name="phone" type="tel" placeholder="Ex: 123-456-7890" />
                                        </label>
                                    </div>
                                    <div className="w-full px-1">
                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" required htmlFor="email">E-mail:
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" name="email" required placeholder="Ex: JaneDoe@mail.com" />
                                        </label>
                                        <p className="text-xs italic">*Required</p>
                                    </div>
                                </div>

                                <div className="flex flex-wrap -mx-3 mb-6">
                                    <div className="w-full px-2">
                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="message">Question / Message:
                                            <textarea className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none" id="message" name="message" required placeholder="i.e. Questions, Comments, etc..."></textarea>
                                        </label>
                                        <p className="text-xs italic">*Required</p>
                                    </div>
                                </div>
                                <button type="submit" className="mt-6 mb-1 relative h-10 py-2 px-3 mx-auto flex justify-center transition-colors rounded-lg focus:shadow-outline bg-gradient-to-r from-dhgreenfrom to-dhgreento text-white hover:shadow-lg hover:shadow-dhgreen font-semibold">
                                    <span className="text-xs pt-1 sm:pt-0 sm:text-base">Send Message</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}